
/*** Navbar ***/

.bg-yellow{
    background: radial-gradient(ellipse farthest-corner at right bottom, #FFCC00 0%, #FFCC00 8%, #FFCC00 30%, #FFCC00 40%, transparent 80%),
                 radial-gradient(ellipse farthest-corner at left top, #FFCC00 0%, #FFCC00 8%, #FFCC00 25%, #FFCC00 62.5%, #FFCC00 100%);
     color: #000;
 }
 
 .bg-light{
     background-color: #FFCC00!important;
     color: #000!important;
 }
 
 .navbar.sticky-top {
     top: -100px;
     transition: .5s;
 }
 
 .navbar .navbar-brand img {
     max-height: 60px;
 }
 
 .navbar .navbar-nav .nav-link {
     margin-left: 25px;
     padding: 10px 0;
     color: #696E77;
     font-weight: 400;
     outline: none;
 }
 
 .navbar .navbar-nav .nav-link:hover,
 .navbar .navbar-nav .nav-link.active {
     color: var(--primary);
 }
 
 .navbar .dropdown-toggle::after {
     border: none;
     content: "\f107";
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
     vertical-align: middle;
     margin-left: 8px;
 }
 
 @media (max-width: 991.98px) {
     .navbar .navbar-brand img {
         max-height: 45px;
     }
 
     .navbar .navbar-nav {
         margin-top: 20px;
         margin-bottom: 15px;
     }
 
     .navbar .nav-item .dropdown-menu {
         padding-left: 30px;
     }
 }
 
 @media (min-width: 992px) {
     .navbar .nav-item .dropdown-menu {
         display: block;
         border: none;
         margin-top: 0;
         top: 150%;
         right: 0;
         opacity: 0;
         visibility: hidden;
         transition: .5s;
     }
 
     .navbar .nav-item:hover .dropdown-menu {
         top: 100%;
         visibility: visible;
         transition: .5s;
         opacity: 1;
     }
 }
 
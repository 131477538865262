/*** Navbar ***/
.bg-yellow {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FFCC00 0%, #FFCC00 8%, #FFCC00 30%, #FFCC00 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFCC00 0%, #FFCC00 8%, #FFCC00 25%, #FFCC00 62.5%, #FFCC00 100%);
  color: #000;
}

.bg-light {
  background-color: #FFCC00 !important;
  color: #000 !important;
}

.navbar.sticky-top {
  top: -100px;
  transition: 0.5s;
}

.navbar .navbar-brand img {
  max-height: 60px;
}

.navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 10px 0;
  color: #696E77;
  font-weight: 400;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-brand img {
    max-height: 45px;
  }
  .navbar .navbar-nav {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .navbar .nav-item .dropdown-menu {
    padding-left: 30px;
  }
}
@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }
  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}
/*** Header ***/
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: var(--dark);
  border: 12px solid var(--dark);
  border-radius: 3rem;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }
  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.page-header {
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../img/carousel-1.jpg) center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item a,
.page-header .breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

.page-header .breadcrumb-item a:hover,
.page-header .breadcrumb-item.active {
  color: var(--primary);
}

/********** CSS **********/
:root {
  --primary: #FF800F;
  --secondary: #000000;
  --light: #F6F7F8;
  --dark: #010A35;
}

.bg-dark {
  background-color: #131414 !important;
}

a {
  color: #000 !important;
  text-decoration: none;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover,
.btn.btn-secondary,
.btn.btn-outline-secondary:hover {
  color: #FFFFFF;
}

.btn.btn-primary:hover {
  background: var(--secondary);
  border-color: var(--secondary);
}

.btn.btn-secondary:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.about {
  background-color: #FFCC00 !important;
  color: #000 !important;
}

/*** About ***/
.about-1 {
  background: rgba(0, 0, 0, 0.8);
}

/*** Contact ***/
@media (min-width: 992px) {
  .container.contact {
    max-width: 100% !important;
  }
  .container.contact .contact-text {
    padding-left: calc((100% - 960px) / 2 + 0.75rem);
  }
  .container.contact .contact-form {
    padding-right: calc((100% - 960px) / 2 + 0.75rem);
  }
}
@media (min-width: 1200px) {
  .container.contact .contact-text {
    padding-left: calc((100% - 1140px) / 2 + 0.75rem);
  }
  .container.contact .contact-form {
    padding-right: calc((100% - 1140px) / 2 + 0.75rem);
  }
}
@media (min-width: 1400px) {
  .container.contact .contact-text {
    padding-left: calc((100% - 1320px) / 2 + 0.75rem);
  }
  .container.contact .contact-form {
    padding-right: calc((100% - 1320px) / 2 + 0.75rem);
  }
}
.container.contact .contact-text {
  background: rgba(0, 0, 0, 0.8);
}

.container.contact .contact-form {
  background: rgba(255, 255, 255, 0.8);
}

.container.contact .contact-text .h-100,
.container.contact .contact-form .h-100 {
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*** Service ***/
.service-item .service-icon {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-item .service-icon img {
  max-width: 60px;
  max-height: 60px;
}

/*** Searches ***/
.Searches-item .Searches-img::before,
.Searches-item .Searches-img::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: 0.5s;
}

.Searches-item .Searches-img::after {
  left: auto;
  right: 0;
}

.Searches-item:hover .Searches-img::before,
.Searches-item:hover .Searches-img::after {
  width: 50%;
}

.Searches-item .Searches-img .Searches-social {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  z-index: 3;
  opacity: 0;
}

.Searches-item:hover .Searches-img .Searches-social {
  transition-delay: 0.3s;
  opacity: 1;
}

/*** Footer ***/
.footer {
  color: #7F8499;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #7F8499;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #7F8499;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--primary);
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .btn.btn-square {
  color: #7F8499;
  border: 1px solid #7F8499;
}

.footer .btn.btn-square:hover {
  color: var(--light);
  border-color: var(--primary);
}

.footer .copyright {
  padding: 25px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .copyright a {
  color: var(--primary);
}

.footer .copyright a:hover {
  color: #FFFFFF;
}

.bg-primary-1 {
  background-color: #131414;
}

.btn-primary {
  background-color: #131414 !important;
  color: #FFFFFF;
  border: #131414;
}

.btn-primary-1 {
  background-color: #FFCC00 !important;
  color: #000;
  border: #FFCC00;
}

.py-6 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

/*services*/
.div-service {
  background: white;
}

.text-primary {
  color: #000 !important;
}

.contact-bg {
  background: white;
}

h1, h2, h3, h4, h5 {
  font-family: "Libre Baskerville", serif !important;
}
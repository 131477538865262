

/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    background-color: var(--dark);
    border: 12px solid var(--dark);
    border-radius: 3rem;
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.page-header {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(../img/carousel-1.jpg) center center no-repeat;
    background-size: cover;
}

.page-header .breadcrumb-item a,
.page-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--light);
}

.page-header .breadcrumb-item a:hover,
.page-header .breadcrumb-item.active {
    color: var(--primary);
}



/*** Footer ***/
.footer {
    color: #7F8499;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #7F8499;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #7F8499;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .btn.btn-square {
    color: #7F8499;
    border: 1px solid #7F8499;
}

.footer .btn.btn-square:hover {
    color: var(--light);
    border-color: var(--primary);
}

.footer .copyright {
    padding: 25px 0;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--primary);
}

.footer .copyright a:hover {
    color: #FFFFFF;
}

.bg-primary-1{
    background-color: #131414;
}

.btn-primary{
    background-color: #131414!important;
    color: #FFFFFF;
    border: #131414;
}

.btn-primary-1{
    background-color: #FFCC00!important;
    color: #000;
    border: #FFCC00;
}

.py-6 {
    padding-top: 7rem!important;
    padding-bottom: 7rem!important;
}

/*services*/

.div-service {
    background:white ;
}


.text-primary{
    color: #000!important;
}


.contact-bg{
    background: white;
}


h1, h2, h3, h4, h5 {
    font-family: 'Libre Baskerville', serif!important;
}
/********** CSS **********/
:root {
    --primary: #FF800F;
    --secondary: #000000;
    --light: #F6F7F8;
    --dark: #010A35;
}

.bg-dark{
    background-color:#131414!important;
}

 a {
    color: #000!important;
   text-decoration: none;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}



/*** Button ***/
.btn {
    transition: .5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover,
.btn.btn-secondary,
.btn.btn-outline-secondary:hover {
    color: #FFFFFF;
}

.btn.btn-primary:hover {
    background: var(--secondary);
    border-color: var(--secondary);
}

.btn.btn-secondary:hover {
    background: var(--primary);
    border-color: var(--primary);
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


.about{
    background-color: #FFCC00!important;
    color: #000!important;  
}

/*** About ***/
.about-1 {
    background: rgba(0, 0, 0, 0.8) ;
}



/*** Contact ***/
@media (min-width: 992px) {
    .container.contact {
        max-width: 100% !important;
    }

    .container.contact .contact-text {
        padding-left: calc(((100% - 960px) / 2) + .75rem);
    }

    .container.contact .contact-form {
        padding-right: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .container.contact .contact-text  {
        padding-left: calc(((100% - 1140px) / 2) + .75rem);
    }

    .container.contact .contact-form  {
        padding-right: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .container.contact .contact-text  {
        padding-left: calc(((100% - 1320px) / 2) + .75rem);
    }

    .container.contact .contact-form  {
        padding-right: calc(((100% - 1320px) / 2) + .75rem);
    }
}

.container.contact .contact-text {
    background: rgba(0, 0, 0, 0.8);
}

.container.contact .contact-form {
    background: rgba(255, 255, 255, .8);
}

.container.contact .contact-text .h-100,
.container.contact .contact-form .h-100 {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/*** Service ***/
.service-item .service-icon {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-item .service-icon img {
    max-width: 60px;
    max-height: 60px;
}


/*** Searches ***/
.Searches-item .Searches-img::before,
.Searches-item .Searches-img::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    transition: .5s;
}

.Searches-item .Searches-img::after {
    left: auto;
    right: 0;
}

.Searches-item:hover .Searches-img::before,
.Searches-item:hover .Searches-img::after {
    width: 50%;
}

.Searches-item .Searches-img .Searches-social {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    z-index: 3;
    opacity: 0;
}

.Searches-item:hover .Searches-img .Searches-social {
    transition-delay: .3s;
    opacity: 1;
}



